import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import {
  FaBookOpen,
  FaStar,
  FaChartBar,
  FaClock,
  FaUsers,
  FaClipboardList,
  FaArrowUp,
  FaArrowDown,
} from "react-icons/fa";
import ReactApexChart from "react-apexcharts";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import styles from "../assets/css/CourseAnalytics.module.css";

const CourseAnalyticsPage = () => {
  useEffect(() => {
    document.title = "Course Analytics | YouElite";
  }, []);

  // Placeholder data
  const [overallCorrectPercentage, setOverallCorrectPercentage] = useState(75); // in percentage
  const [totalTestsCreated, setTotalTestsCreated] = useState(50);
  const [averageTimePerQuestion, setAverageTimePerQuestion] = useState(45); // in seconds
  const [enrolledStudents, setEnrolledStudents] = useState(200); // Number of enrolled students
  const [totalQuestions, setTotalQuestions] = useState(500); // Total questions in the course

  const [topics, setTopics] = useState([]);

  const [easiestTopic, setEasiestTopic] = useState(null);
  const [hardestTopic, setHardestTopic] = useState(null);

  useEffect(() => {
    // Placeholder data for topics
    const placeholderTopics = [
      {
        name: "Thermodynamics",
        correctPercentage: 80,
        testsCreated: 10,
        averageTimePerQuestion: 50,
      },
      {
        name: "Organic Chemistry",
        correctPercentage: 70,
        testsCreated: 8,
        averageTimePerQuestion: 55,
      },
      {
        name: "Equilibrium",
        correctPercentage: 75,
        testsCreated: 12,
        averageTimePerQuestion: 40,
      },
      {
        name: "Kinetics",
        correctPercentage: 65,
        testsCreated: 15,
        averageTimePerQuestion: 60,
      },
      {
        name: "Atomic Structure",
        correctPercentage: 90,
        testsCreated: 5,
        averageTimePerQuestion: 35,
      },
      // Add more topics as needed
    ];
    setTopics(placeholderTopics);

    // Compute easiest and hardest topics
    if (placeholderTopics.length > 0) {
      // Easiest topic: highest correctPercentage
      const easiest = placeholderTopics.reduce((prev, current) =>
        prev.correctPercentage > current.correctPercentage ? prev : current
      );
      setEasiestTopic(easiest);

      // Hardest topic: lowest correctPercentage
      const hardest = placeholderTopics.reduce((prev, current) =>
        prev.correctPercentage < current.correctPercentage ? prev : current
      );
      setHardestTopic(hardest);
    }
  }, []);

  // Chart data for overall correct percentage per topic
  const correctPercentageChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false, // Disable the toolbar
      },
    },
    title: {
      text: "Correct Percentage per Topic",
      align: "center",
    },
    xaxis: {
      categories: topics.map((topic) => topic.name),
    },
    yaxis: {
      title: {
        text: "Correct Percentage (%)",
      },
      max: 100,
    },
  };

  const correctPercentageChartSeries = [
    {
      name: "Correct Percentage",
      data: topics.map((topic) => topic.correctPercentage),
    },
  ];

  // Chart data for tests created per topic
  const testsCreatedChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false, // Disable the toolbar
      },
    },
    title: {
      text: "Tests Created per Topic",
      align: "center",
    },
    xaxis: {
      categories: topics.map((topic) => topic.name),
    },
    yaxis: {
      title: {
        text: "Number of Tests",
      },
    },
  };

  const testsCreatedChartSeries = [
    {
      name: "Tests Created",
      data: topics.map((topic) => topic.testsCreated),
    },
  ];

  // Chart data for average time per question per topic
  const averageTimeChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false, // Disable the toolbar
      },
    },
    title: {
      text: "Average Time per Question per Topic",
      align: "center",
    },
    xaxis: {
      categories: topics.map((topic) => topic.name),
    },
    yaxis: {
      title: {
        text: "Time (seconds)",
      },
    },
  };

  const averageTimeChartSeries = [
    {
      name: "Average Time",
      data: topics.map((topic) => topic.averageTimePerQuestion),
    },
  ];

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className={styles.contentWrapper}>
          <Row>
            <Col md={12}>
              {/* Course Title */}
              <div className="d-flex align-items-center mb-4">
                <h2 className="me-3">🧪 AP Chemistry - Analytics</h2>
                <FaChartBar size={24} />
              </div>

              {/* Overall Statistics */}
              <Card className={`mb-4 ${styles.customCard}`}>
                <Card.Header className={styles.customCardHeader}>
                  <Card.Title as="h5" className={styles.customCardTitle}>
                    Overall Course Statistics
                  </Card.Title>
                </Card.Header>
                <Card.Body className={styles.customCardBody}>
                  <Row>
                    <Col md={4}>
                      <div className={styles.statCard}>
                        <FaStar size={40} className={styles.statIcon} />
                        <h3>{overallCorrectPercentage}%</h3>
                        <p>% Correct Overall</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={styles.statCard}>
                        <FaBookOpen size={40} className={styles.statIcon} />
                        <h3>{totalTestsCreated}</h3>
                        <p>Number of Tests Created</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={styles.statCard}>
                        <FaClock size={40} className={styles.statIcon} />
                        <h3>{averageTimePerQuestion} sec</h3>
                        <p>Average Time per Question</p>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Course Info */}
              <Card className={`mb-4 ${styles.customCard}`}>
                <Card.Body className={styles.customCardBody}>
                  <Row>
                    <Col md={6}>
                      <div className={styles.infoCard}>
                        <FaUsers size={30} className={styles.infoIcon} />
                        <h5>{enrolledStudents}</h5>
                        <p>Enrolled Students</p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className={styles.infoCard}>
                        <FaClipboardList size={30} className={styles.infoIcon} />
                        <h5>{totalQuestions}</h5>
                        <p>Total Questions</p>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Statistics per Topic */}
              <Card className={`mb-4 ${styles.customCard}`}>
                <Card.Header className={styles.customCardHeader}>
                  <Card.Title as="h5" className={styles.customCardTitle}>
                    Statistics per Topic
                  </Card.Title>
                </Card.Header>
                <Card.Body className={styles.customCardBody}>
                  <Row>
                    {/* Correct Percentage per Topic */}
                    <Col md={4}>
                      <div className={styles.chartContainer}>
                        <ReactApexChart
                          options={correctPercentageChartOptions}
                          series={correctPercentageChartSeries}
                          type="bar"
                          height={350}
                        />
                      </div>
                    </Col>
                    {/* Tests Created per Topic */}
                    <Col md={4}>
                      <div className={styles.chartContainer}>
                        <ReactApexChart
                          options={testsCreatedChartOptions}
                          series={testsCreatedChartSeries}
                          type="bar"
                          height={350}
                        />
                      </div>
                    </Col>
                    {/* Average Time per Question per Topic */}
                    <Col md={4}>
                      <div className={styles.chartContainer}>
                        <ReactApexChart
                          options={averageTimeChartOptions}
                          series={averageTimeChartSeries}
                          type="bar"
                          height={350}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Easiest and Hardest Topics */}
              <Card className={`mb-4 ${styles.customCard}`}>
                <Card.Header className={styles.customCardHeader}>
                  <Card.Title as="h5" className={styles.customCardTitle}>
                    Easiest and Hardest Topics
                  </Card.Title>
                </Card.Header>
                <Card.Body className={styles.customCardBody}>
                  <Row>
                    {/* Easiest Topic */}
                    <Col md={6}>
                      <div className={styles.topicCard}>
                        <h4>
                          <FaArrowUp className={styles.easyIcon} /> Easiest Topic
                        </h4>
                        {easiestTopic ? (
                          <>
                            <h5>{easiestTopic.name}</h5>
                            <p>
                              % Correct: {easiestTopic.correctPercentage}%
                            </p>
                            <p>
                              Avg. Time per Question: {easiestTopic.averageTimePerQuestion} sec
                            </p>
                          </>
                        ) : (
                          <p>No data available.</p>
                        )}
                      </div>
                    </Col>
                    {/* Hardest Topic */}
                    <Col md={6}>
                      <div className={styles.topicCard}>
                        <h4>
                          <FaArrowDown className={styles.hardIcon} /> Hardest Topic
                        </h4>
                        {hardestTopic ? (
                          <>
                            <h5>{hardestTopic.name}</h5>
                            <p>
                              % Correct: {hardestTopic.correctPercentage}%
                            </p>
                            <p>
                              Avg. Time per Question: {hardestTopic.averageTimePerQuestion} sec
                            </p>
                          </>
                        ) : (
                          <p>No data available.</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <Footer className={styles.footer} />
      </div>
    </React.Fragment>
  );
};

export default CourseAnalyticsPage;
