import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  FormControl,
  InputGroup,
  Button,
  Dropdown,
  Modal,
  Spinner,
  Tooltip,
  OverlayTrigger,
  ProgressBar,
} from "react-bootstrap";
import { FaEdit, FaPlus, FaTimes } from 'react-icons/fa';
import ReactApexChart from 'react-apexcharts';
import debounce from "lodash.debounce";
import Header from "../layouts/Header";
import EmojiPicker from 'emoji-picker-react';
import Footer from "../layouts/Footer";
import "../assets/css/CourseList.css"; // Custom CSS for the Courses Page

const CoursesPage = () => {
  useEffect(() => {
    document.title = "Courses | YouElite";
  }, []);

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [editedCourseTitle, setEditedCourseTitle] = useState("");
  const [editedCourseIcon, setEditedCourseIcon] = useState("");
  const [showAddModal, setShowAddModal] = useState(false); // New state for Add Course Modal
  const [newCourseTitle, setNewCourseTitle] = useState(""); // New state for new course title
  const [newCourseIcon, setNewCourseIcon] = useState(""); // New state for new course icon
  const maxCoursesToDisplay = 5;
  const [showEmojiPickerEdit, setShowEmojiPickerEdit] = useState(false); // Separate EmojiPicker for Edit Modal
  const [showEmojiPickerAdd, setShowEmojiPickerAdd] = useState(false); // Separate EmojiPicker for Add Modal

  // Helper function to generate a unique 5-digit course code
  const generateUniqueCourseCode = () => {
    let code;
    const existingCodes = courses.map(course => course.courseCode);
    do {
      code = Math.floor(10000 + Math.random() * 90000).toString();
    } while (existingCodes.includes(code));
    return code;
  };

  // Handle Emoji Selection for Edit Modal
  const handleEmojiClickEdit = (emoji) => {
    setEditedCourseIcon(emoji.emoji);
    setShowEmojiPickerEdit(false); // Close picker after selection
  };

  // Handle Emoji Selection for Add Modal
  const handleEmojiClickAdd = (emoji) => {
    setNewCourseIcon(emoji.emoji);
    setShowEmojiPickerAdd(false); // Close picker after selection
  };

  // Toggle Emoji Picker for Edit Modal
  const toggleEmojiPickerEdit = () => {
    setShowEmojiPickerEdit((prevState) => !prevState); // Toggle picker visibility
  };

  // Toggle Emoji Picker for Add Modal
  const toggleEmojiPickerAdd = () => {
    setShowEmojiPickerAdd((prevState) => !prevState); // Toggle picker visibility
  };

  // Close Emoji Picker for Edit Modal
  const closeEmojiPickerEdit = () => {
    setShowEmojiPickerEdit(false);
  };

  // Close Emoji Picker for Add Modal
  const closeEmojiPickerAdd = () => {
    setShowEmojiPickerAdd(false);
  };

  // Placeholder data for courses
  useEffect(() => {
    // Simulate fetching data
    const placeholderCourses = [
      {
        id: 1,
        title: "AP Chemistry",
        icon: "🧪",
        courseCode: "12345", // Added courseCode
        progress: 100,
        numberOfStudents: 25, // New property
        averageScore: 88,      // New property
      },
      {
        id: 2,
        title: "Geometry",
        icon: "📐",
        courseCode: "67890", // Added courseCode
        progress: 70,
        numberOfStudents: 30, // New property
        averageScore: 75,      // New property
      },
      // Add more placeholder courses as needed
    ];
    setCourses(placeholderCourses);
    setLoading(false);
  }, []);

  const fetchSuggestions = debounce(async (query) => {
    // Implement your suggestion fetching logic here
    // For now, we'll use placeholder suggestions
    if (!query) {
      setSuggestions([]);
      return;
    }

    const placeholderSuggestions = [
      { id: 3, title: "Biology", icon: "🧬" },
      { id: 4, title: "Physics", icon: "🔭" },
      // Add more suggestions as needed
    ];

    setSuggestions(
      placeholderSuggestions.filter((course) =>
        course.title.toLowerCase().includes(query.toLowerCase())
      )
    );
  }, 300);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    fetchSuggestions(query);
    setShowSuggestions(true);
  };

  const handleSearchBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 200);
  };

  const handleSearchFocus = () => {
    if (suggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  const handleAddCourseSuggestion = (course) => {
    if (courses.some((existingCourse) => existingCourse.id === course.id)) {
      console.warn("Course is already in the list");
      return;
    }
    const newCourse = { 
      ...course, 
      courseCode: generateUniqueCourseCode(),
      progress: 0,
      numberOfStudents: 0, // Initialize with default value
      averageScore: 0,      // Initialize with default value
    };
    setCourses([...courses, newCourse]);
    setShowSuggestions(false);
    setSearchQuery(""); // Clear search input after adding
  };

  // New handlers for Add Course Modal
  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
    setNewCourseTitle("");
    setNewCourseIcon("");
    closeEmojiPickerAdd();
  };

  const handleSaveNewCourse = () => {
    // Validate inputs
    if (!newCourseTitle.trim()) {
      alert("Please enter a course title.");
      return;
    }
    if (!newCourseIcon.trim()) {
      alert("Please select an icon for the course.");
      return;
    }

    // Create new course object
    const newCourse = {
      id: Date.now(), // Unique ID based on timestamp
      title: newCourseTitle.trim(),
      icon: newCourseIcon,
      courseCode: generateUniqueCourseCode(),
      progress: 0,
      numberOfStudents: 0, // Initialize with default value
      averageScore: 0,      // Initialize with default value
    };

    // Add to courses list
    setCourses([...courses, newCourse]);

    // Close modal and reset fields
    handleCloseAddModal();
  };

  const handleEditCourse = (course) => {
    setCurrentCourse(course);
    setEditedCourseTitle(course.title);
    setEditedCourseIcon(course.icon);
    setShowEditModal(true);
  };

  const handleSaveCourseChanges = () => {
    // Validate inputs
    if (!editedCourseTitle.trim()) {
      alert("Please enter a course title.");
      return;
    }
    if (!editedCourseIcon.trim()) {
      alert("Please select an icon for the course.");
      return;
    }

    setCourses((prevCourses) =>
      prevCourses.map((course) =>
        course.id === currentCourse.id
          ? { ...course, title: editedCourseTitle.trim(), icon: editedCourseIcon }
          : course
      )
    );
    setShowEditModal(false);
    setCurrentCourse(null);
    setEditedCourseTitle("");
    setEditedCourseIcon("");
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setCurrentCourse(null);
    setEditedCourseTitle("");
    setEditedCourseIcon("");
    closeEmojiPickerEdit();
  };

  // Updated renderTooltip function
  const renderTooltip = (props, course) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <p><strong>Number of Students:</strong> {course.numberOfStudents}</p>
        <p><strong>Average Score:</strong> {course.averageScore}%</p>
      </div>
    </Tooltip>
  );

  const mobileStyles = {
    mainTitle: {
      textAlign: "center",
      marginBottom: "20px",
    },
    inputGroup: {
      marginBottom: "20px",
    },
    summary: {
      marginBottom: "20px",
    },
  };

  // Chart data for the summary side
  const accuracyChartOptions = {
    chart: {
      type: 'bar',
      height: 250, // Reduce the height to make the graph smaller
      toolbar: {
        show: false, // Disable the toolbar (removes the burger menu)
      },
    },
    title: {
      text: 'Overall Accuracy',
      align: 'center',
    },
    xaxis: {
      categories: courses.slice(0, maxCoursesToDisplay).map(course => course.title),
    },
    yaxis: {
      title: {
        text: 'Accuracy (%)',
      },
    },
  };

  const accuracyChartSeries = [
    {
      name: 'Accuracy',
      data: courses.slice(0, maxCoursesToDisplay).map(course => course.averageScore),
    },
  ];

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          <Row className="mb-4">
            <Col>
              <h4 className="main-title mb-0">My Classes</h4>
            </Col>
          </Row>

          <Row className="d-flex flex-column-reverse flex-md-row">
            <Col md={8}>
              <Row className="mb-4" style={mobileStyles.inputGroup}>
                <Col>
                  <div style={{ position: "relative" }}>
                    <InputGroup>
                      <FormControl
                        placeholder="Search for a course"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        onBlur={handleSearchBlur}
                        onFocus={handleSearchFocus}
                      />
                      <Button onClick={handleShowAddModal}>
                        <FaPlus />
                      </Button>
                    </InputGroup>
                    {showSuggestions && suggestions.length > 0 && (
                      <Dropdown.Menu
                        show
                        style={{
                          display: "block",
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                          zIndex: 500,
                          position: "absolute",
                          top: "100%",
                          left: 0,
                        }}
                      >
                        {suggestions.map((suggestion, index) => (
                          <Dropdown.Item key={index} onClick={() => handleAddCourseSuggestion(suggestion)}>
                            {suggestion.icon} {suggestion.title}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    )}
                  </div>
                </Col>
              </Row>

              <Card className="card-course-list">
                <Card.Header>
                  <Card.Title as="h6">List</Card.Title>
                </Card.Header>
                <Card.Body className="course-list-body">
                  {loading ? (
                    <div className="text-center">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  ) : courses.length === 0 ? (
                    <p style={{ textAlign: "center" }}>Your course list is empty. Start adding courses!</p>
                  ) : (
                    <div className="course-grid">
                      {courses.map((course, index) => (
                        <OverlayTrigger
                          key={index}
                          placement="top"
                          delay={{ show: 200, hide: 100 }}
                          overlay={(props) => renderTooltip(props, course)}
                        >
                          <Card
                            className="course-card"
                          >
                            <Card.Body>
                              <div className="course-header">
                                <h5>
                                  {course.icon} {course.title}
                                </h5>
                                <p className="course-code-text">
                                  <strong>Course Code:</strong> {course.courseCode}
                                </p>
                              </div>
                              <div className="course-actions mt-3">
                                <Button
                                  variant="primary"
                                  size="sm"
                                  className="me-2"
                                >
                                  View Stats
                                </Button>
                                <Button
                                  variant="secondary"
                                  size="sm"
                                  onClick={() => handleEditCourse(course)}
                                >
                                  Edit Course
                                </Button>
                              </div>
                            </Card.Body>
                          </Card>
                        </OverlayTrigger>
                      ))}
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} md={4} style={mobileStyles.summary}>
              {/* Right Summary Panel */}
              <Card className="card-summary">
                <Card.Header>
                  <Card.Title as="h6">Summary</Card.Title>
                </Card.Header>
                <Card.Body>
                  {/* Global Performance Overview */}
                  <h6>Global Performance Overview</h6>
                  <p>
                    <strong>Best Performing Subject:</strong> Subject X
                  </p>
                  <p>
                    <strong>Worst Performing Subject:</strong> Subject X
                  </p>
                  <p>
                    <strong>Best Performing Topics:</strong> Topic A
                  </p>
                  <p>
                    <strong>Worst Performing Topics:</strong> Topic X
                  </p>
                  <hr />

                  {/* Chart for Course Completion */}
                  <Row className="mb-3">
                    <Col>
                      <h6>Course Completion</h6>
                      <ProgressBar>
                        <ProgressBar
                          variant="success"
                          now={(courses.filter(course => course.progress === 100).length / (courses.length || 1)) * 100}
                          key={1}
                          label={`${Math.round((courses.filter(course => course.progress === 100).length / (courses.length || 1)) * 100)}%`}
                        />
                        <ProgressBar
                          variant="warning"
                          now={(courses.filter(course => course.progress > 0 && course.progress < 100).length / (courses.length || 1)) * 100}
                          key={2}
                          label={`${Math.round((courses.filter(course => course.progress > 0 && course.progress < 100).length / (courses.length || 1)) * 100)}%`}
                        />
                        <ProgressBar
                          variant="danger"
                          now={(courses.filter(course => course.progress === 0).length / (courses.length || 1)) * 100}
                          key={3}
                          label={`${Math.round((courses.filter(course => course.progress === 0).length / (courses.length || 1)) * 100)}%`}
                        />
                      </ProgressBar>
                      <div className="mt-2">
                        <span className="badge bg-success me-2">
                          Completed: {Math.round((courses.filter(course => course.progress === 100).length / (courses.length || 1)) * 100)}%
                        </span>
                        <span className="badge bg-warning me-2">
                          In Progress: {Math.round((courses.filter(course => course.progress > 0 && course.progress < 100).length / (courses.length || 1)) * 100)}%
                        </span>
                        <span className="badge bg-danger">
                          Not Started: {Math.round((courses.filter(course => course.progress === 0).length / (courses.length || 1)) * 100)}%
                        </span>
                      </div>
                    </Col>
                  </Row>

                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Edit Course Modal */}
          <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
            <Modal.Header>
              <Modal.Title>Edit Course</Modal.Title>
              <Button variant="link" onClick={handleCloseEditModal}>
                <FaTimes />
              </Button>
            </Modal.Header>
            <Modal.Body>
              <FormControl
                className="mb-3"
                placeholder="Course Title"
                value={editedCourseTitle}
                onChange={(e) => setEditedCourseTitle(e.target.value)}
              />
              <FormControl
                className="mb-3"
                placeholder="Course Icon (e.g., 📘)"
                value={editedCourseIcon}
                onChange={(e) => setEditedCourseIcon(e.target.value)}
                onFocus={toggleEmojiPickerEdit}
                onBlur={() => setTimeout(closeEmojiPickerEdit, 200)} // Close with a delay to allow emoji selection
              />
              <p className="mb-3">
                <strong>Course Code:</strong> {currentCourse && currentCourse.courseCode}
              </p>
              <Button variant="secondary" onClick={toggleEmojiPickerEdit} className="mb-3">
                {editedCourseIcon || "Select Icon"}
              </Button>
              {showEmojiPickerEdit && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                    zIndex: 1000,
                    width: '280px', // Set a fixed width for the picker
                    height: '300px', // Set a fixed height
                    overflow: 'hidden', // Ensure no extra scrolling
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for visibility
                    borderRadius: '8px', // Rounded corners
                    backgroundColor: 'white', // Ensure background color consistency
                  }}
                >
                  <EmojiPicker
                    onEmojiClick={handleEmojiClickEdit}
                    disableSearchBar={false} // Keeps search bar for usability
                    preload={true} // Preloads emojis to avoid delay
                    pickerStyle={{ width: '100%', height: '100%' }} // Make picker fit container
                  />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEditModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSaveCourseChanges}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Add Course Modal */}
          <Modal show={showAddModal} onHide={handleCloseAddModal} centered>
            <Modal.Header>
              <Modal.Title>Add New Course</Modal.Title>
              <Button variant="link" onClick={handleCloseAddModal}>
                <FaTimes />
              </Button>
            </Modal.Header>
            <Modal.Body>
              <FormControl
                className="mb-3"
                placeholder="Course Title"
                value={newCourseTitle}
                onChange={(e) => setNewCourseTitle(e.target.value)}
              />
              <FormControl
                className="mb-3"
                placeholder="Course Icon (e.g., 📘)"
                value={newCourseIcon}
                onChange={(e) => setNewCourseIcon(e.target.value)}
                onFocus={toggleEmojiPickerAdd}
                onBlur={() => setTimeout(closeEmojiPickerAdd, 200)} // Close with a delay to allow emoji selection
              />
              <Button variant="secondary" onClick={toggleEmojiPickerAdd} className="mb-3">
                {newCourseIcon || "Select Icon"}
              </Button>
              {showEmojiPickerAdd && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                    zIndex: 1000,
                    width: '280px', // Set a fixed width for the picker
                    height: '300px', // Set a fixed height
                    overflow: 'hidden', // Ensure no extra scrolling
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for visibility
                    borderRadius: '8px', // Rounded corners
                    backgroundColor: 'white', // Ensure background color consistency
                  }}
                >
                  <EmojiPicker
                    onEmojiClick={handleEmojiClickAdd}
                    disableSearchBar={false} // Keeps search bar for usability
                    preload={true} // Preloads emojis to avoid delay
                    pickerStyle={{ width: '100%', height: '100%' }} // Make picker fit container
                  />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseAddModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSaveNewCourse}>
                Add Course
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <Footer className="footer" />
      </div>
    </React.Fragment>
  );
};

export default CoursesPage;
